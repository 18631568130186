import React, {useState, useEffect} from 'react';
import { withRouter } from "react-router-dom";
import { Row, Col, Modal, Input, Button } from 'antd';
import axios from "axios";

const { TextArea } = Input;

function ShowHistory(props) {

    const [Prompt, setPrompt] = useState('');
    const [Answer, setAnswer] = useState('');
    const [Temperature, setTemperature] = useState(0);
    const [Top_p, setTop_p] = useState(1);
    const [Freq_pen, setFreq_pen] = useState(2);
    const [Pres_pen, setPres_pen] = useState(2);
    const [MaxTokens, setMaxTokens] = useState(1000);

    return (
        <Row>
            <Col span={18}>
                Select User
            </Col>
            
        </Row>
    )
}

export default withRouter(ShowHistory)
