import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Input, Select, Spin } from 'antd';
import axios from "axios";
import { Switch } from 'antd';
import {USER_SERVER} from '../../../config'
import TestChat from './TestChat';
import TestAssi from './TestAssi';
import TestRPG from './TestRPG';
const { TextArea } = Input;

function TestTwoUser(props) {
    const [Id, setId] = useState(props.init_id);
    const [Question, setQuestion] = useState('');
    const [TotalAnswer, setTotalAnswer] = useState('');
    const [Langcode2, setLangcode2] = useState('en');
    const [WaitAnswer, setWaitAnswer] = useState(false);
    const [CurrMode, setCurrMode] = useState('partner');
    const [CurrPartner, setCurrPartner] = useState('');
    const [PartnerChat, setPartnerChat] = useState('');
    const [PartnerAssi, setPartnerAssi] = useState('');
    const [PartnerRPG, setPartnerRPG] = useState('');
    const [QuotaUsage, setQuotaUsage] = useState('');

    useEffect(() => {
        let val;
        const mem_name = 'mem'+CurrPartner;
        val = localStorage.getItem(mem_name); if (val) {setTotalAnswer(val);}

        val = localStorage.getItem('login_id'); if (val) {setId(val);}
    }, []);

    async function reload_userinfo() {
        const res = await axios.post(`${USER_SERVER}/get_user`, {id:Id});
        console.log('--------->', res);
        if (res.data.success){
            setQuotaUsage(res.data.data.quota_usage);
        }
    }

    function get_mem_name(partner=null){
        if (partner)
            return 'mem'+partner;
        else
            return 'mem'+CurrPartner;
    }

    function onChangeId(e) {
        const id =  e.currentTarget.value;
        setId(id);
        localStorage.setItem('login_id', id);
    }

    async function onClickLogin(){
        const dataToCommit = {
            id:Id,
            email:Id+'@rpg.com',
            name: 'name_' + Id
        }
        //debugger;
        const res = await axios.post(`${USER_SERVER}/signin`, dataToCommit);
        console.log('--------->', res);
        if (res.data.success){
            if (res.data.data.method==='signin'){
                const userinfo = res.data.data.userinfo;
                setLangcode2(userinfo.langcode.substring(0,2));
                setCurrMode(userinfo.current);
                setPartnerChat(userinfo.partner);
                setPartnerAssi(userinfo.assi);
                setPartnerRPG(userinfo.rpg);

                let curr_partner;
                if (userinfo.current==='assi'){
                    curr_partner = userinfo.assi;
                } else if (userinfo.current==='rpg'){
                    curr_partner = userinfo.rpg;
                } else {
                    curr_partner = userinfo.partner;
                }
                setCurrPartner(curr_partner);
                const mem_name = get_mem_name(curr_partner);
                const val = localStorage.getItem(mem_name); if (val) {setTotalAnswer(val);}
            } else {
                onClickSelectFriend();
            }            
        } else{
            alert('Error:'+res.data.data);
        }

        await reload_userinfo();

        //await get_system_info();
        //await test();
    }

    async function test() {
        const res = await axios.post(`${USER_SERVER}/get_select_list`, {id:Id, country:'ko_KR'});
        console.log('--------->', res);
    }

    async function onClickSelectFriend() {
        let dataToCommit;
        if (Langcode2=='ko') {
            dataToCommit = {
                id:Id,
                nickname:'현빈',
                partner: '하나',
                langcode: 'ko_KR',
                level: 0,
                sub_level: 0,
                star_point: 0,
                show_mind: true
            }
        }
        else if (Langcode2=='ja'){
            dataToCommit = {
                id:Id,
                nickname:'jake',
                partner: 'ミユキ',
                langcode: 'ja_JP',
                level: 0,
                sub_level: 0,
                star_point: 0,
                show_mind: true
            }
        }
        else if (Langcode2=='en'){
            dataToCommit = {
                id:Id,
                nickname:'jake',
                partner: 'Ava',
                langcode: 'en_US',
                level: 0,
                sub_level: 0,
                star_point: 0,
                show_mind: true,
                current: 'partner'
            }
        } else {
            alert('unknown langcode2 (ko, ja, en)')
        }

        const res = await axios.post(`${USER_SERVER}/write_db`, dataToCommit);
        console.log('--------->', res);
        if (res.data?.success) {
            alert(`you signed up!!!\n nickname: ${dataToCommit.nickname}\n partner: ${dataToCommit.partner}`);

        } else {
            alert('Error:'+res.data.data);
        }

    }

    async function onClickSelect(mode, new_partner){
        let total_answer='';

        //debugger;
        if (new_partner===CurrPartner){
            return;
        }

        
        // backend에 select_rpg하고
        //debugger;
        const res = await axios.post(`${USER_SERVER}/change_select`, {mode:mode, id:Id, new: new_partner});
        console.log('--------->', res);
        if (res.data?.success) {
            // 새 rpg화면 불러오고
            mem_name = 'mem'+new_partner;
            let val = localStorage.getItem(mem_name); 
            if (val) {
                total_answer = val;
            }

            // 현재 rpg화면 저장하고
            let mem_name = get_mem_name();
            localStorage.setItem(mem_name, TotalAnswer);
            //alert(res.data.data);
        } else {
            console.log('change_select returned false:'+res.data.data);
            return
        }

        //debugger;
        // 처음이라면 get_answer(get_introduction)
        const dataToCommit = {
            id:Id
        }
        
        const res1 = await axios.post(`${USER_SERVER}/get_introduction`, dataToCommit);
        console.log('--------->', res1);
        let newtotal;
        if (res1.data?.success) {
            const part_a = 'STARTING MENT: ' + res1.data.data[0];
            newtotal = total_answer + '\n' + part_a;
        } else {
            newtotal = total_answer;
        }

        if (mode==='assi'){
            setPartnerAssi(new_partner);
        } else if (mode==='rpg'){
            setPartnerRPG(new_partner);
        } else {
            setPartnerChat(new_partner);
        }
        setCurrMode(mode);
        setCurrPartner(new_partner);
        updateTotalAnswer(new_partner, newtotal);
    }

    async function onClickReset() {
        const mode = CurrMode;
        const partner = CurrPartner;
        // 현재 rpg화면 저장하고
        // let mem_name = get_mem_name(SelectedId);
        // localStorage.setItem(mem_name, '');
        // setTotalAnswer('');

        // debugger;
        // backend에 reset_rpg하고
        const res = await axios.post(`${USER_SERVER}/reset_history`, {mode, id:Id, partner});
        console.log('reset--------->', res);
        if (res.data?.success) {
            //alert(res.data.data);
        } else {
            alert('Error:'+res.data.data);
        }

        // 처음이라면 get_answer(get_introduction)
        const dataToCommit = {
            id:Id
        }
        
        const res1 = await axios.post(`${USER_SERVER}/get_introduction`, dataToCommit);
        console.log('--------->', res);
        if (res1.data?.success) {
            //debugger;
            const part_a = 'STARTING MENT: ' + res1.data.data[0];
            const newtotal = part_a;
            updateTotalAnswer(null, newtotal);
        } else {
            alert(`Error: ${res1.data.data}`);
        }
    }


    function updateTotalAnswer(partner, total_answer) {
        const mem_name = get_mem_name(partner);
        localStorage.setItem(mem_name, total_answer);
        setTotalAnswer(total_answer);
    }

    async function onBlur() {
        const mem_name = 'mem'+CurrPartner;
        localStorage.setItem(mem_name, TotalAnswer);
    }

    async function onClickSend(){
        setWaitAnswer(true);

        const dataToCommit = {
            id:Id,
            question:Question.trim(),
            tts: false
        }
        
        const res = await axios.post(`${USER_SERVER}/get_answer`, dataToCommit);
        setWaitAnswer(false);
        console.log('--------->', res);
        if (res.data?.success) {
            const part_q = '\nUser: ' + Question.trim();
            const part_a = '----> ' + res.data.data;
            const newtotal = TotalAnswer + '\n' + part_q + '\n' + part_a;
            updateTotalAnswer(null, newtotal);
        } else {
            alert(`Error: ${res.data.data}`);
        }

        await reload_userinfo();
    }

    return (        
        <div>
            <Row>
                <Col span={8}>
                    {/* <Row style={{margin:'20px'}} align='middle'>
                        <Col>
                            등록을 위해서 처음 login하는 것이라면 언어를 적어주세요: Langcode2 (ko, ja, en): 
                        </Col>
                        <Col>
                            <Input value={Langcode2} onChange={(e)=>setLangcode2(e.currentTarget.value)} lang='en' style={{width:100}}/>
                        </Col>
                    </Row> */}

                    <Row style={{margin:'20px'}}>
                        <Col>Id:</Col>
                        <Col>
                            <Input value={Id} onChange={onChangeId} onPressEnter={onClickLogin} lang='en'/>
                        </Col>
                        <Col>
                            <Button type='primary' onClick={onClickLogin}>Login</Button>
                        </Col>
                    </Row>

                    <Row style={{marginTop:20}}>
                        <TestChat onClickSelect={onClickSelect} CurrPartner={PartnerChat}/>
                    </Row>
                    <Row style={{marginTop:20}}>
                        <TestAssi onClickSelect={onClickSelect} CurrPartner={PartnerAssi}/>
                    </Row>
                    <Row style={{marginTop:20}}>
                        <TestRPG onClickSelect={onClickSelect} CurrPartner={PartnerRPG}/>
                    </Row>

                    <Row style={{marginTop:20}}>
                    Subscription : {QuotaUsage[0]}<br/>
                    {QuotaUsage[1]}<br/>
                    {QuotaUsage[2]}<br/>
                    </Row>
                </Col>
                <Col span={16}>
                    <Row justify="center">
                        <Col span={22}>
                            <TextArea rows={20} value={TotalAnswer} onChange={e=>updateTotalAnswer(null, e.target.value)} onBlur={onBlur}/>
                        </Col>
                    </Row>

                    <Row justify="center" align='middle'>
                        Player:
                        <Input value={Question} onChange={(e)=>setQuestion(e.currentTarget.value)} onPressEnter={onClickSend} style={{width:'85%', margin:10}}/>
                    </Row>

                    <Row justify="center" align='middle'>
                        <Col>
                            ({CurrMode} / {CurrPartner})
                        </Col>
                        <Col>
                        {
                            WaitAnswer? <Spin/> :
                                        <Button type='primary' onClick={onClickSend}  style={{margin:10}}>
                                            Send
                                        </Button>
                        }
                        </Col>
                        <Col>
                            <Button type='primary' onClick={onClickReset} style={{marginLeft:30}}>Reset and Restart</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default TestTwoUser
