import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Input, Select, Divider } from 'antd';
import axios from "axios";
import { Switch } from 'antd';
import {USER_SERVER} from '../../../config'

function AdminPage(props) {
    const [OpenaiKey, setOpenaiKey] = useState(null);
    const [GptTimeout, setGptTimeout] = useState(null);
    const [Password, setPassword] = useState(null);
    // const [CleanupStartUserIdx, setCleanupStartUserIdx] = useState(0);
    // const [CleanupEndUserIdx, setCleanupEndUserIdx] = useState(10000000);
    //const [NumCompletionLog, setNumCompletionLog] = useState(0);
    const [RevcatId, setRevcatId] = useState('');
    const [TransactionId, setTransactionId] = useState('');
    const [UserInfo, setUserInfo] = useState('');
    const [TransactionInfo, setTransactionInfo] = useState('');

    function onChangeOpenaiKey(e) {
        setOpenaiKey(e.currentTarget.value);
    }

    function onChangeGptTimeout(e) {
        setGptTimeout(e.currentTarget.value);
    }

    function onChangePassword(e) {
        setPassword(e.currentTarget.value);
    }

    function onChangeRevcatId(e) {
        setRevcatId(e.currentTarget.value);
    }

    function onChangeTransactionId(e) {
        setTransactionId(e.currentTarget.value);
    }

    async function onClickSendOpenaiKey() {
        const dataToCommit = {
            password:Password,
            openai_key:OpenaiKey
        }
        await onClickSend(dataToCommit);
    }

    async function onClickSendGptTimeout() {
        const dataToCommit = {
            password:Password,
            gpt_timeout:GptTimeout
        }
        await onClickSend(dataToCommit);
    }

    async function onClickSend(dataToCommit) {
        const res = await axios.post(`${USER_SERVER}/set_admin_param`, dataToCommit);
        console.log('--------->', res);
        if (res.data.success){
            alert('Login:success:'+ res.data.data);
        } else{
            alert('Error:'+res.data.data);
        }

    }

    // async function onClickCleanupHistory() {
    //     const dataToCommit = {
    //         CleanupStartUserIdx:CleanupStartUserIdx,
    //         CleanupEndUserIdx:CleanupEndUserIdx
    //     }
        
    //     const res = await axios.post(`${USER_SERVER}/cleanup_history_manually`, dataToCommit);
    //     console.log('--------->', res);
    //     if (res.data.success){
    //         alert('Login:success:'+ res.data.data);
    //     } else{
    //         alert('Error:'+res.data.data);
    //     }

    // }

    // async function onClickNumCompletionLog() {
    //     const dataToCommit = {
    //         num: NumCompletionLog
    //     }
        
    //     const res = await axios.post(`${USER_SERVER}/admin/capture_completion_log`, dataToCommit);
    //     console.log('--------->', res);
    //     if (res.data.success){
    //         alert('Login:success:'+ res.data.data);
    //     } else{
    //         alert('Error:'+res.data.data);
    //     }

    // }

    async function onClickFindUserInfo() {
        const res = await axios.post(`${USER_SERVER}/find_user_from_revcatid`, {revcat_uid: RevcatId});
        console.log('--------->', res);
        if (res.data.success){
            setUserInfo(res.data.data);
            setTransactionInfo(null);
        } else{
            alert('Error:'+res.data.data);
        }
    }

    async function onClickTransaction() {
        const res = await axios.post(`${USER_SERVER}/find_user_from_transaction_id`, {transaction_id: TransactionId});
        console.log('--------->', res);
        if (res.data.success){
            setUserInfo(res.data.data.userinfo);
            setTransactionInfo(res.data.data.transaction);
        } else{
            alert('Error:'+res.data.data);
        }
    }

    return (
        <div>
            <h3>Admin</h3> 
            <Row style={{margin:'20px'}}>
                <Col>Admin password:</Col>
                <Col>
                    <Input value={Password} onChange={onChangePassword}/>
                </Col>
            </Row>

            <Row style={{margin:'20px'}}>
                <Col>OpenAI key change:</Col>
                <Col>
                    <Input value={OpenaiKey} onChange={onChangeOpenaiKey}/>
                </Col>
                <Button type='primary' onClick={onClickSendOpenaiKey} disabled={!Password}>
                    Send With Password
                </Button>
            </Row>

            <Row style={{margin:'20px'}}>
                <Col>GPT Timeout(response를 받기위해 기다리는 최대시간):</Col>
                <Col>
                    <Input value={GptTimeout} onChange={onChangeGptTimeout}/>
                </Col>
                <Button type='primary' onClick={onClickSendGptTimeout} disabled={!Password}>
                    Send With Password
                </Button>
            </Row>

            {/* <Row style={{margin:'20px'}}>
                CleanupStartUserIdx: <Input value={CleanupStartUserIdx} onChange={(e)=>setCleanupStartUserIdx(e.currentTarget.value)}/>
                CleanupEndUserIdx: <Input value={CleanupEndUserIdx} onChange={(e)=>setCleanupEndUserIdx(e.currentTarget.value)}/>
                <Button type='primary' onClick={onClickCleanupHistory}>
                    Clean Up History Manually
                </Button>
            </Row> */}

            {/* <Row style={{margin:'20px'}}>
                개발을 위해서 Prompt와 response를 저장할 수 있습니다. Completion Log를 capture 할 개수를 지정하고 버튼을 누르세요.
                Number of Completion to write: <Input value={NumCompletionLog} onChange={(e)=>setNumCompletionLog(e.currentTarget.value)}/>
                <Button type='primary' onClick={onClickNumCompletionLog}>
                    Activate Completion Log
                </Button>
            </Row> */}

            <Row> <Divider/> </Row>
            <Row> <h2>Revenucat user id &#x2192; userinfo </h2></Row>

            <Row style={{margin:'20px'}}>
                <Col>
                    <Input value={RevcatId} onChange={onChangeRevcatId} style={{width:540}} placeholder="8021884ed89c3fbf42af6ba7329a00bf3fda6d7e27d488d1e67215090182f7e7"/>
                </Col>
                <Col>
                    <Button type='primary' onClick={onClickFindUserInfo}>
                        Find
                    </Button>
                </Col>
            </Row>

            <Row> <h2>Search by Transaction Id &#x2192; userinfo and transaction </h2></Row>
            <Row style={{margin:'20px'}}>
                <Col>
                    <Input value={TransactionId} onChange={onChangeTransactionId} style={{width:540}} placeholder="GPA.3343-0422-0222-34247"/>
                </Col>
                <Col>
                    <Button type='primary' onClick={onClickTransaction}>
                        Find
                    </Button>
                </Col>
            </Row>

            <Row> <b>Email:</b> {UserInfo.email} </Row>
            <Row> <b>Subscription:</b> {UserInfo.subscription} </Row>
            <Row> <b>Expire date:</b> {UserInfo.expire_date} </Row>
            <Row> <b>Country:</b> {UserInfo.langcode} </Row>
            {TransactionInfo? <Row> <b>Transaction:</b> <pre> {JSON.stringify(TransactionInfo, null, 2)} </pre></Row>: <div></div>}
        </div>
    )
}

export default AdminPage
