import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Input, Spin } from 'antd';
import axios from "axios";
import { Switch } from 'antd';
import {USER_SERVER} from '../../../config'
const { TextArea } = Input;

function TestAssi(props) {
    const id2rpg = ['Lily'];

    async function onClickStartRPG(id_rpg) {
        props.onClickSelect('assi', id2rpg[id_rpg]);
    }

    // async function onClickReset() {
    //     props.onClickReset('assi', props.CurrPartner);
    // }


    return (
        <div>
            <h3>TESTING Assi</h3> 

            <Row align='middle'>
                <Button type='primary' onClick={()=>onClickStartRPG(0)} style={{marginLeft:30}}>Start {id2rpg[0]}</Button>

                {/* <Button type='primary' onClick={onClickReset} style={{marginLeft:30}}>Reset and Restart</Button> */}
                {/* <div style={{marginLeft:30}}>(current default: {CurrPartner})</div> */}
            </Row>

            {/* <Row justify="center">
                <Col span={22}>
                    <TextArea rows={20} value={TotalAnswer} onChange={e=>updateTotalAnswer(SelectedId, e.target.value)} onBlur={onBlur}/>
                </Col>
            </Row>

            <Row justify="center" align='middle'>
                Player:
                <Input value={Question} onChange={onChangeQuestion} onPressEnter={onClickSend} style={{width:'85%', margin:10}}/>
                {
                    WaitAnswer? <Spin/> :
                                <Button type='primary' onClick={onClickSend}  style={{margin:10}}>
                                    Send
                                </Button>
                }
            </Row> */}
        </div>
    )
}

export default TestAssi
