import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Input, Spin } from 'antd';
import axios from "axios";
import { Switch } from 'antd';
import {USER_SERVER} from '../../../config'
const { TextArea } = Input;

function TestChat(props) {
    const id2rpg = ['Chloe', 'Quinn', 'Sophia', 'Luna', 'Kate', 'Ava', 'Clara', 'Hazel', 'Fiona','리사'];

    function set_color(id) {
        const color = (id2rpg[id]===props.CurrPartner)? 'red':'white';
        return color;
    }

    // useEffect(()=>{
    //     const len = 
    // }, [])



    async function onClickStartRPG(id_rpg) {
        props.onClickSelect('partner', id2rpg[id_rpg]);
    }

    // async function onClickReset() {
    //     props.onClickReset('partner', props.CurrPartner);
    // }




    return (
        <div>
            <h3>TESTING Chat</h3> 

            <Row align='middle'>
                <Button type='primary' onClick={()=>onClickStartRPG(0)} style={{margin:10, color:set_color(0)}}>Start {id2rpg[0]}</Button>
                <Button type='primary' onClick={()=>onClickStartRPG(1)} style={{margin:10, color:set_color(1)}}>Start {id2rpg[1]}</Button>
                <Button type='primary' onClick={()=>onClickStartRPG(2)} style={{margin:10, color:set_color(2)}}>Start {id2rpg[2]}</Button>
                <Button type='primary' onClick={()=>onClickStartRPG(3)} style={{margin:10, color:set_color(3)}}>Start {id2rpg[3]}</Button>
                <Button type='primary' onClick={()=>onClickStartRPG(4)} style={{margin:10, color:set_color(4)}}>Start {id2rpg[4]}</Button>
                <Button type='primary' onClick={()=>onClickStartRPG(5)} style={{margin:10, color:set_color(5)}}>Start {id2rpg[5]}</Button>
                <Button type='primary' onClick={()=>onClickStartRPG(6)} style={{margin:10, color:set_color(6)}}>Start {id2rpg[6]}</Button>
                <Button type='primary' onClick={()=>onClickStartRPG(7)} style={{margin:10, color:set_color(7)}}>Start {id2rpg[7]}</Button>
                <Button type='primary' onClick={()=>onClickStartRPG(8)} style={{margin:10, color:set_color(8)}}>Start {id2rpg[8]}</Button>
                <Button type='primary' onClick={()=>onClickStartRPG(9)} style={{margin:10, color:set_color(9)}}>Start {id2rpg[9]}</Button>
{/* 
                <Button type='primary' onClick={onClickReset} style={{margin:10}}>Reset and Restart</Button> */}
                {/* <div style={{marginLeft:30}}>(current default: {CurrPartner})</div> */}
            </Row>
        </div>
    )
}

export default TestChat
