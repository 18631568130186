import React, {useState, useEffect} from 'react';
import { withRouter } from "react-router-dom";
import { Row, Col, Tabs, Input, Button } from 'antd';
import axios from "axios";
import moment from 'moment';
import {USER_SERVER} from '../../../config';
import ShowHistory from './Sections/ShowHistory';

const { TextArea } = Input;

function AnalysisPage(props) {
    // const [MinNum, setMinNum] = useState(0);
    const [QueryUsageStartIdx, setQueryUsageStartIdx] = useState(0);
    const [UserList, setUserList] = useState([]);
    const [HistoryList, setHistoryList] = useState([]);

    // async function onClickUpdateNumDB() {
    //     const res = await axios.post(`${USER_SERVER}/users/update_usage_collection`, {});
    //     if (res.data.success) {
    //         alert('OK');
    //     } else {
    //         alert('fail: '+res.data.data);
    //     }
    // }

    // async function onClickSearch() {
    //     const dataToSubmit ={
    //         from_count: parseInt(MinNum),
    //         ret_count: 20
    //     }
    //     const res = await axios.post(`${USER_SERVER}/users/query_usage`, dataToSubmit);
    //     if (res.data.success) {
    //         setUserList(res.data.data);
    //     } else {
    //         alert('fail: '+res.data.data);
    //     }
    // }

    // function onChangeMinNum(e) {
    //     setMinNum(e.target.value);
    // }

    async function onClickQueryUsageCollection() {
        const dataToCommit = {
            startidx:QueryUsageStartIdx,
            req_length: 10
        }
        const res = await axios.post(`${USER_SERVER}/users/query_usage_collection`, dataToCommit);
        if (res.data.success) {
            setUserList(res.data.data);
        } else {
            alert('fail: '+res.data.data);
        }
    }

    async function onClickUser(user){
        const dataToSubmit ={
            id: user.id,
            start_idx: 0,
            req_length: 100
        }
        const res = await axios.post(`${USER_SERVER}/users/get_all_history_for_user`, dataToSubmit);
        if (res.data.success) {
            setHistoryList(res.data.data);
        } else {
            alert('fail: '+res.data.data);
        }
    }

    const render_users = UserList && UserList.map(function(user, index){
        //console.log(user);
        return (
            <tr key={index}>
                <td>{index+1}</td>
				<td><Button onClick={()=>onClickUser(user)}>{user._id}</Button></td>
                <td>{user.id}</td>
                <td>{user.num_history}</td>
            </tr>
        );
		}
    );


	function show_users() {
		return (<>
			<table style={{ width: '80%' }}>
				<thead>
					<tr>
						<th>Index</th>
						<th>_id</th>
						<th>ID</th>
						<th>Num Chat</th>
					</tr>
				</thead>
				<tbody>
					{render_users}
				</tbody>
			</table>
		</>)
	}

    ////////////////////////// History //////////////////////////////////
    const render_history = HistoryList && HistoryList.map(function(history, index){
        //console.log(user);
        const ttime = new Date(history.ts);
        const tstr = moment(ttime).format("yyyy-MM-DD HH:mm:ss")
        return (
            <tr key={index}>
                <td>{tstr}</td>
				<td>{history.speaker==='h'? 'Man':'Woman'}</td>
                <td>{history.message}</td>
            </tr>
        );
		}
    );


	function show_history() {
		return (<>
			<table style={{ width: '80%' }}>
				<thead>
					<tr>
						<th>Time</th>
						<th>Man/Woman</th>
						<th>Chat</th>
						<th>Intimacy</th>
						<th>Inner</th>
					</tr>
				</thead>
				<tbody>
					{render_history}
				</tbody>
			</table>
		</>)
	}


    return (
        <>
            This Page is under construction !!!
        {/* <Row>
            최근 등록자의 chatting 사용 회수를 검색할 수 있습니다. <br/>
            최근 몇 번째 등록자를 검색하시겠습니까?
        </Row>
        <Row>
            <Input value={QueryUsageStartIdx} onChange={e=>setQueryUsageStartIdx(e.target.value)} style={{width:100, margin:20}}/>
            <Button type='primary' onClick={onClickQueryUsageCollection} style={{margin:20}}>Search</Button>
        </Row>

        <Row align='center'>
            {show_users()}
        </Row>
        <Row align='center'>
            {show_history()}
        </Row> */}
        </>
)
}

export default withRouter(AnalysisPage)



// function AnalysisPage(props) {

//     const items =[
//         {
//             label:"Select User", 
//             key:"1", 
//             children:   <>
//                         <SelectUser/>
//                         </>
//         },
//         {
//             label:"Corporate Stamp", 
//             key:"2", 
//             children:   <>
//                         <ShowHistory/>
//                         </>
//         }
//     ];
    
//     return (
//         <Row>
//             <Col span={18}>
//                 <Tabs defaultActiveKey="1" items={items}/>
//             </Col>
//         </Row>
//     )
// }

// export default withRouter(AnalysisPage)
