import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Input, Modal } from 'antd';
import axios from "axios";
import { Switch } from 'antd';
import {USER_SERVER116} from '../../../../config'
const { TextArea } = Input;


function TestCategory(props) {
    const [ChatAIList, setChatAIList] = useState('');
    const [ModalOpen, setModalOpen] = useState(false);

    async function onClickCategory(category_id) {
        const res = await axios.post(`${USER_SERVER116}/get_ailist_by_category`, {category_id, user_id:props.UserId});
        console.log('get_ailist_by_category--------->', res);
        if (res.data.success){
            setChatAIList(res.data.data.list);
            setModalOpen(true);
        }
    }

    function onClickAI(ai_id){
        props.onClickAI(ai_id);
        setModalOpen(false);
    }
    
    const render_category_list = props.CategoryList && props.CategoryList.map(function(category, index){
        // console.log(category);
        return (
    		<Button key={index} onClick={()=>onClickCategory(category.id)}>{category.name}</Button>
        );
		}
    );

    const render_ailist = ChatAIList && ChatAIList.map(function(ai, index){
        // console.log(ai);
        return (
    		<Button key={index} onClick={()=>onClickAI(ai.id)} style={{margin:5}}>{ai.name}({ai.count_history})</Button>
        );
		}
    );

    return (
        <div>
            <h3>TESTING Category</h3> 

            <Row align='middle'>
                {render_category_list}
            </Row>

            <Modal title="Select AI" 
                open={ModalOpen} 
                destroyOnClose={true}
                footer={[
                    <Button key="back" type='primary' onClick={()=>setModalOpen(false)} >
                        Cancel
                    </Button>]}
                >
                {render_ailist}
            </Modal>

            {/* <Row align='middle' style={{marginTop:20}}>
                Select AI: <br/>
                {render_ailist}
            </Row> */}
        </div>
    )
}

export default TestCategory
