import React, { useState } from "react";

import axios from "axios";
import { Modal } from "antd";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { GoogleLogin } from "react-google-login";
import { Link, withRouter } from "react-router-dom";
import { getCookie } from "../../../cookie/cookie";
//import { GOOGLE_OAUTH_CLIENT_ID } from "../../../config";
import { loginUser, loginGoogleUser } from "../../../_actions/user_actions";

function LoginPage(props) {

    const dispatch = useDispatch();

    const [Userinfo, setUserinfo] = useState("");
    const [Password, setPassword] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(true);

    const onUserinfoHandler = (event) => {
        setUserinfo(event.currentTarget.value);
    };

    const onPasswordHandler = (event) => {
        setPassword(event.currentTarget.value);
    };

    function respond_to_result(response) {
        //debugger;
        const resdata = response.payload;
        console.log('resdata=', resdata);

        if (resdata.loginSuccess) {
            let message = ("Logged in successfully.");
            Swal.fire(("Success"), message, "success");
            props.history.push("/");
        } else {
            Swal.fire({ icon: "error", title: ("Oops..."), text: ('Something went wrong!!!') });
        }
    }

    const onSubmitHandler = (event) => {

        event.preventDefault();
        const body = { userinfo: Userinfo, password: Password };
        dispatch(loginUser(body)).then((response) => {
            respond_to_result(response);
        });
    };

    function onSuccessGoogle(googleUser) {

        var id_token = googleUser.getAuthResponse().id_token;
        const body = { token: id_token };
        dispatch(loginGoogleUser(body)).then((response) => {
            respond_to_result(response);
        });
    };

    function onFailureGoogle(result) {
        console.log("fail google", result);
    }

    
    // function onSuccessFacebook(res) {
    //   //console.log(res);
    //   //getFacebookUserData(res.accessToken);
    //   let dataToSubmit = {
    //     accessToken: res.accessToken,
    //   };
    //   dispatch(loginFacebookUser(dataToSubmit)).then((response) => {
    //     if (response.payload.loginSuccess) {
    //       props.history.push("/");
    //     } else {
    //       console.log("error--->", response.payload);
    //       alert("Error2");
    //     }
    //   });
    // }

    function handleOk() {
        setIsModalVisible(false);
    }

    function handleCancel() {
        setIsModalVisible(false);
        props.history.push("/");
    }

    function onClickRegister() {
        props.history.push("/register");
    }

    return (
        <div>
            <Modal className="sign-in-up" title={("Sign In")} open={isModalVisible} onOk={handleOk} onCancel={handleCancel}
                maskClosable={true} // 모달밖 영역 눌렀을때 닫는 기능 비활성화
                cancelButtonProps={{ style: { display: "none" } }} okButtonProps={{ style: { display: "none" } }}>
                <div style={{ textAlign: "center" }}>
                    <p className="sign-or">{("or")}</p>
                    <div>
                        <form className="sign-form" style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                            <label>{("Userinfo")}</label>
                            <input type="userinfo" value={Userinfo} onChange={onUserinfoHandler} />
                            <label>{("Password")}</label>
                            <input type="password" value={Password} onChange={onPasswordHandler} />
                            <br />
                            <Link to={"/#"} id={"newly_track_btn"} className="continue-btn" onClick={onSubmitHandler} type="primary">
                                {("Continue")}
                            </Link>
                        </form>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default withRouter(LoginPage);
