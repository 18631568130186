import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Input, Spin } from 'antd';
import axios from "axios";
import { Switch } from 'antd';
import {USER_SERVER} from '../../../config'
const { TextArea } = Input;

function TestRPG(props) {
    const id2rpg = ['en_accidental', 'en_nextdoor', 'en_room'];

    function set_color(id) {
        const color = (id2rpg[id]===props.CurrPartner)? 'red':'white';
        return color;
    }

    //////////////////////////////////////////////////////////////

    async function onClickStartRPG(id_rpg) {
        props.onClickSelect('rpg', id2rpg[id_rpg]);
    }

    // async function onClickReset() {
    //     props.onClickReset('rpg', props.CurrPartner);
    // }


    return (
        <div>
            <h3>TESTING RPG</h3> 

            <Row align='middle'>
                <Button type='primary' onClick={()=>onClickStartRPG(0)} style={{margin:10, color:set_color(0)}}>Start {id2rpg[0]}</Button>
                <Button type='primary' onClick={()=>onClickStartRPG(1)} style={{margin:10, color:set_color(1)}}>Start {id2rpg[1]}</Button>
                <Button type='primary' onClick={()=>onClickStartRPG(2)} style={{margin:10, color:set_color(2)}}>Start {id2rpg[2]}</Button>

                {/* <Button type='primary' onClick={onClickReset} style={{margin:10}}>Reset and Restart</Button> */}
                {/* <div style={{marginLeft:30}}>(current default: {CurrPartner})</div> */}
            </Row>

            {/* <Row justify="center">
                <Col span={22}>
                    <TextArea rows={20} value={TotalAnswer} onChange={e=>updateTotalAnswer(SelectedId, e.target.value)} onBlur={onBlur}/>
                </Col>
            </Row>

            <Row justify="center" align='middle'>
                Player:
                <Input value={Question} onChange={onChangeQuestion} onPressEnter={onClickSend} style={{width:'85%', margin:10}}/>
                {
                    WaitAnswer? <Spin/> :
                                <Button type='primary' onClick={onClickSend}  style={{margin:10}}>
                                    Send
                                </Button>
                }
            </Row> */}
        </div>
    )
}

export default TestRPG
