import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Auth from "../hoc/auth";

import LoginPage from "../components/views/02_LoginPage/LoginPage";
import LandingPage from '../components/views/10_LandingPage/LandingPage';
import ScenarioPage from "../components/views/10_LandingPage/ScenarioPage";
import TestTwoUser from "../components/views/10_LandingPage/TestTwoUser";
import TestHome116 from "../components/views/10_LandingPage/v116/TestHome116";
import TestFunction from '../components/views/10_LandingPage/TestFunction';
import TestFunction116 from '../components/views/10_LandingPage/v116/TestFunction116';
import AdminPage from "../components/views/10_LandingPage/AdminPage";
import AnalysisPage from '../components/views/10_LandingPage/AnalysisPage';

import Page404 from "../components/views/ErrorPages/Page404";

const Routes = () => {

    return (
        <Switch>
            {/* null-->누구나 접근가능, true-->로그인한 사용자만 접근가능, false-->로그인 안한 사용자만 접근가능 */}
            <Route exact path="/login" component={Auth(LoginPage, false)} />
			<Route exact path="/scenario" component={Auth(ScenarioPage, null)} />
			<Route exact path="/testing" component={Auth(TestTwoUser, true)} />
			<Route exact path="/testing116" component={Auth(TestHome116, true)} />
			<Route exact path="/test_function116" component={Auth(TestFunction116, true)} />
			<Route exact path="/test_function" component={Auth(TestFunction, true)} />
			<Route exact path="/analysis" component={Auth(AnalysisPage, true)} />
			<Route exact path="/admin" component={Auth(AdminPage, true)} />
			<Route exact path="/" component={Auth(LandingPage, null)} />
            <Route component={Page404} exact />

        </Switch>
    );
};

export default Routes;